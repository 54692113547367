import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BarraSuperior from './Components/BarraSuperior';

import Inicio from './Components/Inicio';
import Noticias from './Components/Noticias';
import Tendencias from './Components/Tendencias';
import More from './Components/More';

import CarrerasTecnicas from './Components/NoticiasComponents/CarrerasTecnicas';
import ChuyaDiazLinchar from './Components/NoticiasComponents/ChuyaDiazLinchar';
import ApoyoComunidad0809 from './Components/NoticiasComponents/ApoyoComunidad0809';

import './App.css';

export default function App() {
  return (
    <Router basename="/">
      <div className="app-container">
        <BarraSuperior />
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/noticias" element={<Noticias />} />
          <Route path="/tendencias" element={<Tendencias />} />
          <Route path="/mas-informacion" element={<More />} />

          <Route path="/carreras-tecnicas" element={<CarrerasTecnicas />} />
          <Route path="/chuya-diaz-linchar" element={<ChuyaDiazLinchar />} />
          <Route path="/apoyo-comunidad-0809" element={<ApoyoComunidad0809 />} />
        </Routes>
      </div>
    </Router>
  );
}
