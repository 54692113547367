import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Carousel, Card } from 'react-bootstrap';
import BarraFacebook from './BarraFacebook';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../CSS/Inicio.css';

const API_KEY = 'c183d82f13944fce8d803734240809'; // Clave API de WeatherAPI
const CITY = 'Huejutla';

export default function Inicio() {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await axios.get(`https://api.weatherapi.com/v1/current.json?key=${API_KEY}&q=${CITY}&aqi=no`);
        setWeather(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching weather data:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchWeatherData();
  }, []);

  if (loading) {
    return <p>Cargando clima...</p>;
  }

  if (error) {
    return <p>Error cargando datos del clima. Inténtalo de nuevo más tarde.</p>;
  }

  // Determina el color de fondo según el estado del día
  const isDay = weather.current.is_day;
  const cardBgColor = isDay
    ? 'linear-gradient(195deg, #f2ff40, #4287ff, #005eff)'
    : 'linear-gradient(195deg, #85638b, #59495c, #313131)';

  return (
    <div className="inicio">
      <BarraFacebook />
      <br />

      <div className="inicioContent">
        <Carousel className="carousel">
          <Carousel.Item>
            <img
              className="d-block w-100 imgCarousel"
              src={process.env.PUBLIC_URL + '/Img/Banner.jpg'}
              alt="Banner 1"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 imgCarousel"
              src={process.env.PUBLIC_URL + '/Img/Banner2.jpg'}
              alt="Banner 2"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 imgCarousel"
              src={process.env.PUBLIC_URL + '/Img/Banner3.jpg'}
              alt="Banner 3"
            />
          </Carousel.Item>
        </Carousel>
      </div>

      <Card className="card-clima" style={{ background: cardBgColor }}>
        <Card.Body>
          <Card.Title className='textCardTitle'>Clima Actual</Card.Title>
          {weather && weather.current ? (
            <div className="weather-info">
              <div className="weather-details">
                <h5>{weather.location.name}, {weather.location.region}, {weather.location.country}</h5>
                <br></br>
                <p><strong>Temperatura:</strong> {weather.current.temp_c} °C / {weather.current.temp_f} °F</p>
                <p><strong>Velocidad del Viento:</strong> {weather.current.wind_kph} km/h</p>
                <p><strong>Fecha y Hora:</strong> {weather.location.localtime}</p>
                <p><strong>Precipitación:</strong> {weather.current.precip_mm} mm</p>
              </div>
              <img
                src={`https:${weather.current.condition.icon}`}
                alt={weather.current.condition.text}
                className="weather-icon"
              />
            </div>
          ) : (
            <p>Datos del clima no disponibles.</p>
          )}
        </Card.Body>
      </Card>

      <br />
      <br />
    </div>
  );
}
