import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const noticia = {
  titulo: 'Urgente: Piden apoyo de la comunidad para localizar a joven desaparecido en Huejutla',
  fecha: '08/09/2024',
  fuente: 'Huasteco Informativo',
  contenido: `Se solicita el apoyo de la comunidad para localizar en #Huejutla, #Hidalgo a un joven de 24 años, identificado como Benigno Vargas Castillo, quien ha desaparecido misteriosamente. Su familia y las autoridades ministeriales están llevando a cabo una intensa búsqueda para dar con su paradero.

Benigno fue visto por última vez el 03 de septiembre de este año, y desde entonces no se ha tenido noticia alguna de su paradero. Según la ficha de búsqueda con reporte número FDBHGO/235/2024, emitida por la Procuraduría General de Justicia del Estado de Hidalgo (PGJEH), el joven mide 1.60 metros, tiene cabello corto y lacio de color negro, ojos cafés oscuros y pesa 60 kilos.

La PGJEH hace un llamado a la ciudadanía para colaborar en la búsqueda de Benigno Vargas Castillo. Si alguien tiene información que pueda ayudar, se solicita comunicarse a la línea de Procuratel al 8009121314.`,
};

export default function ApoyoComunidad0809() {
  return (
    <div className="container mt-4">
      <div style={{ marginTop: '100px' }}>
        <Link to="/noticias"><h1>Regresar... </h1></Link>
        <h2 className="mb-3">{noticia.titulo}</h2>
        <h6 className="text-muted mb-3">{noticia.fecha} | Fuente: {noticia.fuente}</h6>
        <p>{noticia.contenido}</p>
      </div>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        marginBottom: '40px' 
      }}>
        <img
          src={process.env.PUBLIC_URL + '/Img/imgNoticias/ApoyoComunidad0809.jpg'}
          alt={noticia.titulo}
          style={{ 
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', 
            borderRadius: '10px'
          }}
          className="img-fluid"
        />
      </div>
      <br></br>
      <br></br>
    </div>
  );
}
