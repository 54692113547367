import React from 'react';
import '../CSS/Noticias.css';
import { Link } from 'react-router-dom'; // Importa Link

const noticias = [
  {
    titulo: 'Urgente: Piden apoyo de la comunidad para localizar a joven desaparecido en Huejutla',
    fecha: '08/09/2024',
    fuente: 'Huasteco Informativo',
    contenido: `Se solicita el apoyo de la comunidad para localizar en #Huejutla, #Hidalgo a un joven de 24 años, identificado como Benigno Vargas Castillo, quien ha desaparecido misteriosamente...`,
    enlace: '/apoyo-comunidad-0809'
  },
  {
    titulo: 'Chuya Díaz provoca polémica con discurso incendiario en el Senado',
    fecha: '07/09/2024',
    fuente: 'Huasteco Informativo',
    contenido: `La senadora Chuya Díaz ha sido criticada por incitar a "linchar" a los senadores 
    que apoyen la reforma judicial propuesta por AMLO. Aunque su discurso ha sido calificado 
    como violento, ella no ha mostrado intención de retractarse...`,
    enlace: '/chuya-diaz-linchar'
  },
  {
    titulo: 'Hidalgo, segundo lugar nacional con más jóvenes con carreras técnicas desaprovechadas: IMCO',
    fecha: '29/08/2024',
    fuente: 'El Sol de Hidalgo',
    contenido: `Hidalgo es el segundo estado en México con más jóvenes que no aprovechan sus 
    carreras técnicas. El 9% de los estudiantes en la entidad están en estas carreras, solo 
    superado por Aguascalientes. Las carreras más demandadas son Derecho, Ingeniería 
    Industrial, Administración de Empresas, Enfermería y Comercio. Las mujeres prefieren...`,
    enlace: '/carreras-tecnicas'
  }
];

export default function Noticias() {
  return (
    <div className="noticias-container">
      {noticias.map((noticia, index) => (
        <div key={index} className='noticias-array'>

          <div className='card'>
            <div className="card-content">
              <h2 className="card-title">{noticia.titulo}</h2>
              <p className="card-date">{noticia.fecha}</p>
              <p className="card-source">Fuente: {noticia.fuente}</p>
              <p className="card-text">{noticia.contenido}</p>
              <Link to={noticia.enlace} className="read-more-link">Ver más...</Link> {/* Usa Link en lugar de <a> */}
            </div>
          </div>

        </div>
      ))}
    </div>
  );
}
