import React from 'react';
import '../CSS/BarraFacebook.css';

export default function BarraFacebook() {
  const handleButtonClick = () => {
    window.open('https://www.facebook.com/share/CkApkPGTx4uWd6h8/?mibextid=qi2Omg', '_blank');
  };

  return (
    <div className="barra-superior">
      <br></br>
      <div className='barTitleButtom'>
        <h1 className='textHeader'>NOTICIAS, VERDAD Y TRANSPARENCIA | </h1>
        <button className="botonFB" onClick={handleButtonClick}>
          <div className='btnFlex'>
            <img className="navLogoImg" src="/Img/FacebookLogo.png" alt="Facebook.com" />
            <h2 className='textButtonFB'>HuastecoInformativo</h2>
          </div>
        </button>
      </div>
    </div>
  );
}
