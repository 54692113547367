import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../CSS/BarraSuperior.css';

export default function BarraSuperior() {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  return (
    <div className="barra-principal">
      <div className="perfil">
        <img src={process.env.PUBLIC_URL + '/Img/Logo.jpg'} alt="Logo" className="logo" />
        <h2 className='WhiteText'>Huasteco Informativo</h2>
        <button className="menu-toggle" onClick={toggleMenu}>
          &#9776; {/* Icono de menú hamburguesa */}
        </button>
      </div>
      <nav className={`menu ${menuVisible ? 'visible' : 'hidden'}`}>
        <ul>
          <li><Link to="/" onClick={closeMenu}>Inicio</Link></li>
          <li><Link to="/noticias" onClick={closeMenu}>Directorio de Noticias</Link></li>
          <li><a href="/tendencias" onClick={closeMenu}>Tendencias</a></li>
          <li><a href="/mas-informacion" onClick={closeMenu}>Política de privacidad</a></li>
        </ul>
      </nav>
    </div>
  );
}
