import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../CSS/More.css';

export default function More() {
  return (
    <div className='more'>
      <div className="alert alert-warning contenidoPrivacidad">
        <h1 className="alert-heading">Política de privacidad</h1>
        <p>
          El sitio web "Huasteco Informativo" proporciona noticias e información con el
          objetivo de informar y mantener a la audiencia actualizada sobre los eventos
          de interés. Sin embargo, el equipo editorial de "Huasteco Informativo" no
          garantiza la exactitud, veracidad o integridad de las noticias y artículos
          publicados. La información es presentada de buena fe y con el mayor cuidado
          posible para ofrecer contenido relevante y preciso.
        </p>
        <p>
          <strong>Responsabilidad del Lector:</strong> El lector es el único responsable
          de cómo interpreta y utiliza la información disponible en este sitio. Se
          recomienda que el lector verifique la información con otras fuentes confiables
          y consulte expertos cuando sea necesario para obtener una visión completa y
          precisa sobre los temas tratados.
        </p>
        <p>
          <strong>Exclusión de Responsabilidad Legal:</strong> De conformidad con las leyes
          mexicanas, "Huasteco Informativo" no asume ninguna responsabilidad por daños,
          pérdidas o perjuicios directos o indirectos que puedan derivarse del uso de la
          información contenida en el sitio. El contenido del sitio no debe ser considerado
          como asesoramiento profesional, legal, médico o financiero.
        </p>
        <p>
          <strong>Derechos de Autor y Propiedad Intelectual:</strong> Todo el contenido del
          sitio, incluidos textos, imágenes, gráficos y otros materiales, está protegido
          por derechos de autor y leyes de propiedad intelectual. Queda prohibida la
          reproducción, distribución o modificación del contenido sin la autorización
          expresa del equipo editorial.
        </p>
        <p>
          <strong>Actualización del Deslinde de Responsabilidad:</strong> "Huasteco
          Informativo" se reserva el derecho de modificar o actualizar este deslinde de
          responsabilidad en cualquier momento. El uso continuado del sitio implica la
          aceptación de cualquier cambio realizado.
        </p>
        <p>
          Para consultas adicionales sobre el contenido del sitio, por favor, contacte al
          equipo editorial a través del siguiente enlace: 
          <a href="https://www.facebook.com/share/CkApkPGTx4uWd6h8/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
            Contactar al equipo editorial
          </a>.
        </p>
      </div>
    </div>
  );
}
