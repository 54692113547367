import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'; // Importa Link

const noticia = {
  titulo: 'Chuya Díaz provoca polémica con discurso incendiario en el Senado',
  fecha: '07/09/2024',
  fuente: 'Huasteco Informativo',
  contenido: `La senadora Chuya Díaz, del partido PAN, ha generado controversia al llamar a "linchar" a los senadores que voten a favor de la reforma judicial propuesta por el presidente AMLO. Aunque ha recibido críticas por su discurso violento, la senadora no se ha retractado. Es importante recordar que la incitación a la violencia es un delito en México y puede tener graves consecuencias legales. La política debe ser un espacio para el debate y la discusión respetuosa, no para la violencia o la intimidación.`,
};

export default function ChuyaDiazLinchar() {
  return (
    <div className="container mt-4">
      <div style={{ marginTop: '100px' }}>
        <Link to="/noticias"><h1>Regresar... </h1></Link> {/* Cambiado a Link */}
        <h2 className="mb-3">{noticia.titulo}</h2>
        <h6 className="text-muted mb-3">{noticia.fecha} | Fuente: {noticia.fuente}</h6>
        <p>{noticia.contenido}</p>
      </div>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', /* Centra horizontalmente */
        marginBottom: '40px' 
      }}>
        <img
          src={process.env.PUBLIC_URL + '/Img/imgNoticias/ChuyaDiazLinchar.jpg'}
          alt={noticia.titulo}
          style={{ 
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', 
            borderRadius: '10px'
          }}
          className="img-fluid"
        />
      </div>
      <br></br>
      <br></br>
    </div>
  );
}
