import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../CSS/Tendencias.css';

export default function Tendencias() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          'https://newsapi.org/v2/top-headlines?country=us&category=business&apiKey=16d665785dcf465ba00747c0dcdb45d1'
        );
        setArticles(response.data.articles);
        setLoading(false);
      } catch (err) {
        setError('Error fetching news');
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className='tendencias'>
      <h1 className='mb-4'>Noticias en Tendencia</h1>
      <div className='row'>
        {articles.map((article, index) => (
          <div key={index} className='col-md-4'>
            <div className='card mb-4'>
              <img
                src={article.urlToImage}
                className='card-img-top'
                alt={article.title}
                style={{ height: '200px', objectFit: 'cover' }}
              />
              <div className='card-body'>
                <h5 className='card-title'>{article.title}</h5>
                <p className='card-text'>{article.description}</p>
                <a href={article.url} target='_blank' rel='noopener noreferrer' className='btn btn-primary'>
                  Leer más
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
