import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'; // Importa Link

const noticia = {
  titulo: 'Hidalgo, segundo lugar nacional con más jóvenes con carreras técnicas desaprovechadas: IMCO',
  fecha: '29/08/2024',
  fuente: 'El Sol de Hidalgo',
  contenido: `Según el estudio “Compara Carreras 2024” del Instituto Mexicano para la Competitividad (IMCO), Hidalgo se posiciona como el segundo estado a nivel nacional con el mayor porcentaje de jóvenes con carreras técnicas desaprovechadas. El 9% de los estudiantes de nivel superior en la entidad se han formado en este tipo de carreras, cifra solo superada por Aguascalientes, donde el porcentaje alcanza el 10%.
  
  El estudio también detalla que las carreras más demandadas en Hidalgo son Derecho, Ingeniería Industrial, Administración de Empresas, Enfermería, y Comercio y Negocios. A pesar de esto, el área de Leyes sigue siendo la más popular entre los egresados de las instituciones de educación superior del estado.
  
  Por otro lado, los datos muestran un cambio de tendencia en los intereses académicos por sexo. Las mujeres están optando cada vez más por carreras en Enfermería y Educación, mientras que los hombres siguen mostrando interés en Leyes, además de un creciente interés en Ingeniería Industrial y Comercio y Negocios.
  
  En cuanto a los ingresos, las carreras mejor remuneradas en Hidalgo están en las áreas de matemáticas, estadísticas, ingenierías, manufacturas, construcción, y tecnologías de la información y la comunicación. Por el contrario, las carreras técnicas presentan ingresos promedio de apenas 120 pesos al día, siendo las menos rentables junto con algunas áreas de Educación. Las licenciaturas con menores ingresos económicos incluyen Terapia y Rehabilitación, Bellas Artes, Nutrición, Gastronomía y Sociología y Antropología.
  
  Frente a este escenario, el IMCO enfatiza la necesidad de adaptar las habilidades de los jóvenes a las tendencias globales para mejorar su competitividad en el mercado laboral. Además, recomienda a la Secretaría de Educación Pública de Hidalgo (SEPH) implementar estrategias para prevenir la deserción escolar y fomentar una educación superior más alineada con las demandas actuales del mercado.`,
};

export default function CarrerasTecnicas() {
  return (
    <div className="container mt-4">
      <div style={{ marginTop: '100px' }}>
        <Link to="/noticias"><h1>Regresar... </h1></Link> {/* Cambiado a Link */}
        <h2 className="mb-3">{noticia.titulo}</h2>
        <h6 className="text-muted mb-3">{noticia.fecha} | Fuente: {noticia.fuente}</h6>
        <p>{noticia.contenido}</p>
      </div>
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', /* Centra horizontalmente */
        marginBottom: '40px' 
      }}>
        <img
          src={process.env.PUBLIC_URL + '/Img/imgNoticias/CarrerasTecnicas.jpg'}
          alt={noticia.titulo}
          style={{ 
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)', 
            borderRadius: '10px'
          }}
          className="img-fluid"
        />
      </div>
      <br></br>
      <br></br>
    </div>
  );
}
